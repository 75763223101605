import React, { useState, useRef, useEffect, forwardRef } from "react"
import PropTypes from "prop-types"
import ScrollLock from "components/ScrollLock"
import FocusTrap from "focus-trap-react"
import {
  DropdownTitle,
  DropdownLink,
  DropdownContact,
} from "components/navigation/Header/Dropdown"
import Highlight from "components/navigation/Header/Highlight"
import NavButton from "components/navigation/NavButton"
import InvisibleButton from "components/buttons/InvisibleButton"
import { columnType, sectionType } from "./DesktopNav"
import useMediaQuery from "hooks/useMediaQuery"
import useViewportHeight from "hooks/useViewportHeight"
import useArrowFocus from "hooks/useArrowFocus"
import useMergedRefs from "hooks/useMergedRefs"
import theme from "styles/theme"

import { ReactComponent as RightArrow } from "images/navigation/rightArrow.svg"
import { ReactComponent as MarathonMiniIcon } from "images/header/headerMiniIcon.svg"
import { ReactComponent as CloseButtonX } from "images/icons/x.svg"
import { ReactComponent as BackArrow } from "images/navigation/backArrow.svg"

const MobileNav = ({ sections, closeMenu }) => {
  const [selectedNav, setSelectedNav] = useState()
  const mobileNavActive = useMediaQuery(theme.navBreak)
  const subNavRef = useRef()

  // on selected nav change, focus on first element in the SubNav
  useEffect(() => {
    if (selectedNav) {
      const subNav = subNavRef.current
      const firstSubNavItem = subNav.querySelector("a, button")
      firstSubNavItem?.focus()
    }
  }, [selectedNav])

  return (
    <ScrollLock isActive={mobileNavActive}>
      {forwardRef((props, ref) => (
        <FocusTrap ref={ref} active={mobileNavActive}>
            <NavContainer
            navTitle={selectedNav}
            closeMenu={closeMenu}
            onNavigateBack={() => setSelectedNav(null)}
            >
            <NavTabs
                tabs={sections.map(({ title }) => (
                <Tab
                    key={title}
                    title={title}
                    isActive={selectedNav === title}
                    onClick={() => setSelectedNav(title)}
                />
                ))}
                content={
                selectedNav && (
                    <SubNav
                    ref={subNavRef}
                    {...sections.find(({ title }) => title === selectedNav)}
                    onNavigate={closeMenu}
                    />
                )
                }
            />
            </NavContainer>
        </FocusTrap>
      ))}
    </ScrollLock>
  )
}

export default MobileNav

const NavContainer = React.forwardRef(
  ({ navTitle, onNavigateBack, closeMenu, children }, ref) => {
    const mobile = useMediaQuery(theme.mobile)
    const viewportHeight = useViewportHeight("100vh")
    const focusRef = useArrowFocus(["ArrowDown", "ArrowUp"])
    const mergedRef = useMergedRefs(ref, focusRef)

    return (
      <div
        ref={mergedRef}
        css={{
          position: "fixed",
          background: "#fff",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: 2,
          height: viewportHeight,
          overflowY: "auto",
          WebkitOverflowScrolling: "touch",
        }}
      >
        {/* Heading */}
        <div
          css={{
            background: theme.white,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "sticky",
            top: 0,
            zIndex: 3,
            borderBottom: `1px solid ${theme.slate}`,
          }}
        >
          {navTitle && mobile ? (
            <>
              <NavButton
                icon={<BackArrow aria-hidden />}
                onClick={onNavigateBack}
                text="Back to previous menu"
              />
              <NavTitle title={navTitle} />
            </>
          ) : (
            <MarathonMiniIcon
              css={{
                width: 52,
                marginLeft: 30,
              }}
              aria-hidden
            />
          )}

          <NavButton
            text="Close Menu"
            icon={<CloseButtonX aria-hidden />}
            onClick={closeMenu}
            css={{ color: theme.midBlue }}
          />
        </div>

        {/* Content */}
        {children}
      </div>
    )
  }
)

NavContainer.displayName = "NavContainer"

const NavTabs = ({ tabs, content }) => (
  <div
    css={{
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      backgroundColor: theme.lightGray,
      minHeight: "calc(100% - 92px)",
      [theme.mobile]: {
        minHeight: "calc(100% - 77px)",
        display: "flex",
      },
    }}
  >
    <div
      css={{
        backgroundColor: theme.nearWhite,
        display: "flex",
        flexDirection: "column",
        padding: "64px 0 64px 72px",

        [theme.mobile]: {
          display: content ? "none" : "flex",
          paddingLeft: 40,
          paddingRight: 40,
          flex: "1 0 100%",
        },
      }}
    >
      {tabs}
    </div>

    {content}
  </div>
)

const NavTitle = ({ title }) => (
  <div
    css={{
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: "-0.01em",
      color: theme.gray,
    }}
  >
    {title}
  </div>
)

NavTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

const Tab = ({ title, isActive, onClick }) => (
  <InvisibleButton
    css={[
      theme.headerNavLink,
      {
        display: "block",
        marginLeft: 0,
        paddingBottom: 16,
        color: isActive ? theme.midBlue : theme.gray,
        cursor: "pointer",
        [theme.mobile]: {
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        },
      },
      {
        borderBottom: `1px solid ${isActive ? theme.midBlue : theme.lightGray}`,
        marginBottom: 53,
        ":last-child": {
          marginBottom: 0,
        },
      },
    ]}
    onClick={onClick}
  >
    {title}
    <RightArrow
      css={{
        display: "none",
        [theme.mobile]: {
          width: 22,
          display: "block",
        },
      }}
      aria-hidden
    />
  </InvisibleButton>
)

const SubNav = React.forwardRef(
  ({ subsections, highlight, onNavigate, className }, ref) => {
    const collapsible = subsections.length > 3
    const [openSubsection, setOpenSubsection] = useState(false)

    return (
      <div
        ref={ref}
        css={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          background: theme.white,
          [theme.mobile]: {
            flex: "1 0 100%",
          },
        }}
        className={className}
      >
        <div
          css={{
            padding: "74px 66px 50px",
            [theme.mobile]: {
              padding: "60px 72px 50px 40px",
            },
            [theme.smallMobilePortrait]: {
              paddingRight: 40,
            },
          }}
        >
          {subsections.map(({ title, icon, links, contacts }) => (
            <div
              key={title}
              css={{
                transition: "margin-bottom 300ms",
                marginBottom: collapsible && openSubsection === title ? 42 : 38,
                ":last-child": {
                  marginBottom: 0,
                },
              }}
            >
              <DropdownTitle
                title={title}
                icon={icon}
                collapsible={collapsible}
                expanded={openSubsection === title}
                onClick={() =>
                  setOpenSubsection(openSubsection === title ? false : title)
                }
              />

              <ul
                css={{
                  margin: 0,
                  padding: 0,
                  listStyle: "none",
                  overflow: "hidden",
                  maxHeight:
                    !collapsible || (collapsible && openSubsection === title)
                      ? 50 * ((links.length || 0) + (contacts.length || 0)) +
                        100
                      : 0,
                  transition: "max-height 300ms",
                  visibility:
                    collapsible && openSubsection !== title
                      ? "hidden"
                      : "visible",
                }}
                aria-hidden={collapsible && openSubsection !== title}
              >
                {links?.map(({ title, url }) => (
                  <li key={title}>
                    <DropdownLink
                      title={title}
                      url={url}
                      onClick={onNavigate}
                    />
                  </li>
                ))}

                {contacts?.map(contact => (
                  <DropdownContact key={contact.title} {...contact} />
                ))}
              </ul>
            </div>
          ))}
        </div>

        {highlight && (
          <Highlight
            type="textHighlight"
            {...highlight}
            onNavigate={onNavigate}
          />
        )}
      </div>
    )
  }
)

SubNav.displayName = "SubNav"

MobileNav.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape(sectionType)).isRequired,
  closeMenu: PropTypes.func.isRequired,
}

NavContainer.propTypes = {
  navTitle: PropTypes.string,
  onNavigateBack: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

NavTabs.propTypes = {
  tabs: PropTypes.node.isRequired,
  content: PropTypes.node,
}

Tab.propTypes = {
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}

SubNav.propTypes = {
  className: PropTypes.string,
  highlight: PropTypes.object,
  subsections: PropTypes.arrayOf(PropTypes.shape(columnType)).isRequired,
  onNavigate: PropTypes.func,
}
